import { http } from './http';
import _ from 'lodash';
import TransactionReportRecord from '@/dtos/transactionReportRecord';
import CoinReportRecord from '@/dtos/coinReportRecord';

export function reportTransaction(form): Promise<TransactionReportRecord[]> {
  let body = _.assign(
    {
      currency: '',
      address: '',
      group_by: '',
      from: -1,
      to: -1,
    },
    form,
  );

  return http
    .callApi({
      resource: 'report.transaction',
      data: body,
    })
    .then(e => e.data);
}

export function reportCoin(form = {}): Promise<CoinReportRecord[]> {
  let body = _.assign(
    {
      from: -1,
      to: -1,
    },
    form,
  );

  return http
    .callApi({
      resource: 'report.coin',
      data: body,
    })
    .then(e => e.data);
}
