




import { Component, Prop, Vue } from 'vue-property-decorator';
import ApexCharts from 'apexcharts';
import { mounted } from '@/utils/decorators/VueTimmer';
import { reportTransaction } from '@/apis/report.api';
import TransactionReportRecord from '@/dtos/transactionReportRecord';
import _ from 'lodash';
import { numberWithComma } from '@/services/numberUtils';

@Component({
  props: {
    reportQuery: {
      default: () => {
        return {
          currency: 'ETH',
          symbol: 'ETH',
          address: '',
          group_by: 'date',
        };
      },
    },
  },
})
export default class IoChart extends Vue {
  chart: ApexCharts = null;
  transactionReportRecords: TransactionReportRecord[] = [];
  reportQuery: any;

  @mounted
  reportTransaction() {
    return reportTransaction(this.reportQuery).then(data => {
      this.transactionReportRecords = data;
      this.renderChart();
    });
  }

  get inReport() {
    return _.map(this.transactionReportRecords, 'i');
  }

  get outReport() {
    return _.map(this.transactionReportRecords, 'o');
  }

  get tReport() {
    return _.map(this.transactionReportRecords, 'k');
  }

  renderChart() {
    let options = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      series: [
        {
          name: 'In',
          data: this.inReport,
        },
        {
          name: 'Out',
          data: this.outReport,
        },
      ],
      xaxis: {
        categories: this.tReport,
      },
      yaxis: {
        title: {
          text: `Amount of ${this.reportQuery.symbol}`,
        },
        labels: {
          formatter: val => {
            return numberWithComma(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: val => {
            return `${numberWithComma(val)} ${this.reportQuery.symbol}`;
          },
        },
      },
      legend: {
        position: 'top',
      },
      colors: ['#2983FF', '#FD6A6A'],
    };

    let chart = new ApexCharts(this.$refs.chart, options);
    chart.render();

    this.chart = chart;
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
