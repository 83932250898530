




import { Component, Prop, Vue } from 'vue-property-decorator';
import ApexCharts from 'apexcharts';
import { mounted } from '@/utils/decorators/VueTimmer';
import { reportTransaction } from '@/apis/report.api';
import TransactionReportRecord from '@/dtos/transactionReportRecord';
import _ from 'lodash';
import { numberWithComma } from '@/services/numberUtils';

@Component({
  props: {
    reportQuery: {
      default: () => {
        return {
          currency: 'ETH',
          symbol: 'ETH',
          address: '',
          group_by: 'date',
        };
      },
    },
  },
})
export default class IoChart extends Vue {
  chart: ApexCharts = null;
  transactionReportRecords: TransactionReportRecord[] = [];
  reportQuery: any;

  @mounted
  reportTransaction() {
    return reportTransaction(this.reportQuery).then(data => {
      this.transactionReportRecords = data;
      this.renderChart();
    });
  }

  get cReport() {
    return _.map(this.transactionReportRecords, 'c');
  }

  get kReport() {
    return _.map(this.transactionReportRecords, 'k');
  }

  renderChart() {
    let options = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: 'smooth',
      },
      series: [
        {
          name: '# Transactions',
          data: this.cReport,
        },
      ],
      // title: {
      //   text: 'Product Trends by Month',
      //   align: 'left',
      // },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: this.kReport,
      },
      yaxis: {
        labels: {
          formatter: val => {
            return numberWithComma(val);
          },
        },
      },
    };

    let chart = new ApexCharts(this.$refs.chart, options);
    chart.render();

    this.chart = chart;
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
